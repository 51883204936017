<template>
  <div class="tower-overview">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Tower Overview.png" />
        <span>Tower Overview</span>
      </div>
    </div>

    <div class="tower-overview__content _flex _flex-justify__center">
      <img src="@/assets/tower-background.png" alt="" />

      <div
        v-for="h in heights"
        :key="h.label"
        :data-h="h.label"
        :style="{ bottom: pxConvertRem(h.bottom) }"
      ></div>

      <img
        src="@/assets/icons/dashboard/Camera Direction.png"
        alt=""
        v-for="item in imgs"
        :key="item.bottom"
        :style="{
          ...item.style,
          left: pxConvertRem(item.style.left),
          bottom: pxConvertRem(item.style.bottom)
        }"
        class="camera"
      />
    </div>
  </div>
</template>

<script>
const baseHeight = 28.5
export default {
  data() {
    return {
      heights: [
        {
          label: '5m',
          bottom: baseHeight * 5
        },
        {
          label: '7.5m',
          bottom: baseHeight * 7.5
        },
        {
          label: '10m',
          bottom: baseHeight * 10
        },
        {
          label: '12.5m',
          bottom: baseHeight * 12.5
        },
        {
          label: '15m',
          bottom: baseHeight * 15
        }
      ],

      imgs: [
        {
          style: {
            bottom: baseHeight * 5.6 - 40,
            left: 155,
            transform: 'rotate(-205deg)'
          }
        },
        {
          style: {
            bottom: baseHeight * 7.2 - 40,
            left: 145,
            transform: 'rotate(205deg)'
          }
        },
        {
          style: {
            bottom: baseHeight * 9.5 - 40,

            left: 170,
            transform: 'rotate(-202deg)'
          }
        },
        {
          style: {
            bottom: baseHeight * 12 - 40,
            left: 135,
            transform: 'rotate(205deg)'
          }
        },
        {
          style: {
            bottom: baseHeight * 15 - 40,
            left: 150,
            transform: 'rotate(0deg)'
          }
        }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.tower-overview {
  &__content {
    width: 100%;
    height: calc(100% - 52px);
    position: relative;

    > div {
      position: absolute;
      width: 100%;
      border-bottom: 1px dashed var(--color-info-light-7);

      &::before {
        content: attr(data-h);
        position: absolute;
        bottom: -7px;
        padding: 0 10px;
        color: var(--color-info-light-7);
        background: var(--color-white);
      }
    }

    > .camera {
      position: absolute;
      width: 25px;
      height: 42px;
      object-fit: cover;
    }
  }
}
</style>
